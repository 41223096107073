<template>
  <div>
    <div class="d-flex align-center">
      <v-btn icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="title mx-3">University Details</div>

      <v-spacer />

      <div class="d-flex" style="gap: 10px">
        <v-btn
          color="primary"
          class="text-capitalize"
          outlined
          small
          rounded
          @click="
            addUniversity({
              show: true,
              id: $route.params.id,
              type: 'edit',
            })
          "
        >
          <v-icon small left>mdi-pencil-outline</v-icon>
          Edit
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          outlined
          small
          rounded
          @click="toggleConfirmationDialog({ show: true, action: 'delete' })"
        >
          <v-icon small left>mdi-trash-can-outline</v-icon>
          delete
        </v-btn>
      </div>
    </div>

    <v-card class="mt-6">
      <v-card-title class="text-body-1 font-weight-medium">
        Basic Information
      </v-card-title>

      <v-divider />
      <v-card-text class="">
        <div class="text-center pb-11" v-if="basic_info_loading">
          <v-progress-circular
            :size="60"
            :width="2"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <div class="d-flex flex-wrap" v-if="university" style="gap: 10px 0px">
          <v-sheet width="25%">
            <div class="text--secondary">Name</div>
            <div class="text-capitalize">
              {{ university.name }}
            </div>
          </v-sheet>

          <v-sheet width="25%">
            <div class="text--secondary">Mobile</div>
            <div class="text-capitalize" v-if="university.user.mobile">
              {{ university.user.mobile_display }}
            </div>
            <v-icon v-else>mdi-minus</v-icon>
          </v-sheet>

          <v-sheet width="25%">
            <div class="text--secondary">Email</div>
            <div class="text-capitalize" v-if="university.user.email">
              {{ university.user.mobile_display }}
            </div>
            <v-icon v-else>mdi-minus</v-icon>
          </v-sheet>

          <v-sheet width="25%">
            <div class="text--secondary">Address</div>
            <div
              class="text-capitalize"
              v-if="university.address || university.city"
            >
              <span v-if="university.address">{{ university.address }}, </span>
              <span v-if="university.city"
                >{{ university.city.name }},
                {{ university.city.state.name }}
              </span>
            </div>
            <v-icon v-else>mdi-minus</v-icon>
          </v-sheet>

          <v-sheet width="25%">
            <div class="text--secondary">Remarks</div>
            <div class="text-capitalize" v-if="university.remark">
              {{ university.remark }}
            </div>
            <v-icon v-else>mdi-minus</v-icon>
          </v-sheet>
        </div>

        <v-divider class="my-4" />

        <div class="d-flex flex-wrap" v-if="university" style="gap: 10px 0px">
          <v-sheet width="25%">
            <div class="text--secondary">Operations Executive</div>
            <div v-for="(i, id) in university.executive" :key="id">
              {{ i.name }}
            </div>

            <v-edit-dialog
              @save="onExecutiveSave"
              large
              persistent
              transition="slide-y-transition"
            >
              <v-btn
                x-small
                text
                outlined
                color="primary"
                class="text-capitalize"
              >
                <v-icon x-small class="mr-1">mdi-plus</v-icon>
                Add Executive
              </v-btn>
              <template v-slot:input style="max-width: 100px">
                <div class="my-4">Add Executive</div>
                <v-autocomplete
                  :items="executive_list"
                  v-model="executive"
                  item-text="name"
                  item-value="id"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  style="max-width: 400px"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
          </v-sheet>

          <v-sheet width="25%">
            <div class="text--secondary">FE/IR</div>
            <div v-for="(i, id) in university.fe_ir" :key="id">
              {{ i.name }}
            </div>

            <v-edit-dialog
              @save="onFeIrSave"
              large
              persistent
              transition="slide-y-transition"
            >
              <v-btn
                x-small
                text
                outlined
                color="primary"
                class="text-capitalize"
              >
                <v-icon x-small class="mr-1">mdi-plus</v-icon>
                Add FE/IR
              </v-btn>
              <template v-slot:input>
                <div class="my-4">Add Executive</div>
                <v-autocomplete
                  :items="fe_ir_list"
                  v-model="fe_ir"
                  item-text="name"
                  item-value="id"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  style="max-width: 400px"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
          </v-sheet>
        </div>
      </v-card-text>
    </v-card>

    <!-- Service Cost Start -->
    <v-card class="mt-6">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-body-1 font-weight-medium">Service Cost</div>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-0">
        <v-data-table
          :loading="tableLoader"
          loading-text="Please wait.... Loading"
          :headers="headers"
          :items="tableItems"
          hide-default-footer
          mobile-breakpoint
        >
          <template v-slot:[`item.service_name`]="{ item }">
            <div class="py-4" v-if="item.service">
              <div>{{ item.service.name }}</div>
            </div>
          </template>
          <template v-slot:[`item.university_cost`]="{ item }">
            <div class="d-flex align-center justify-center">
              ₹ {{ item.university_cost }}
              <v-edit-dialog
                @save="onSave(item.id, 'university_cost')"
                @open="cost = item.university_cost"
                large
                transition="slide-y-transition"
              >
                <v-btn icon small
                  ><v-icon small color="primary"
                    >mdi-pencil-outline</v-icon
                  ></v-btn
                >
                <template v-slot:input>
                  <div class="my-4">University Costs</div>
                  <v-text-field
                    v-model="cost"
                    hide-spin-buttons
                    @keydown="$rules.blockInvalidChar"
                    onkeypress="if(value.length==5) return false"
                    label="Enter Cost"
                    height="42px"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </div>
          </template>
          <template v-slot:[`item.university_cost_per_document`]="{ item }">
            <div class="d-flex align-center justify-center">
              ₹ {{ item.university_cost_per_document }}
              <v-edit-dialog
                @save="onSave(item.id, 'university_cost_per_document')"
                @open="cost = item.university_cost_per_document"
                large
                transition="slide-y-transition"
              >
                <v-btn icon small
                  ><v-icon small color="primary"
                    >mdi-pencil-outline</v-icon
                  ></v-btn
                >
                <template v-slot:input>
                  <div class="my-4">University Cost/Document</div>
                  <v-text-field
                    v-model="cost"
                    label="Enter Cost"
                    @keydown="$rules.blockInvalidChar"
                    onkeypress="if(value.length==5) return false"
                    hide-spin-buttons
                    height="42px"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </div>
          </template>
          <template v-slot:[`item.additional_cost`]="{ item }">
            <div class="d-flex align-center justify-center">
              ₹ {{ item.additional_cost }}
              <v-edit-dialog
                @save="onSave(item.id, 'additional_cost')"
                @open="cost = item.additional_cost"
                large
                transition="slide-y-transition"
              >
                <v-btn icon small
                  ><v-icon small color="primary"
                    >mdi-pencil-outline</v-icon
                  ></v-btn
                >
                <template v-slot:input>
                  <div class="my-4">Additional Cost(FE/IR Cost)</div>
                  <v-text-field
                    v-model="cost"
                    label="Enter Cost"
                    @keydown="$rules.blockInvalidChar"
                    onkeypress="if(value.length==5) return false"
                    height="42px"
                    type="number"
                    outlined
                    hide-spin-buttons
                    dense
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Service Cost End -->
    <add-university @reload-list="getUniversityDetail" />
    <ConfirmationDialog @confirmed="deleteUser" height="130px" width="130px">
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
    <br />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import _ from "lodash";
import apiUrl from "@/constant/ApiUrls";
import addUniversity from "./addUniversity.vue";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { addUniversity, ConfirmationDialog },
  data() {
    return {
      basic_info_loading: false,
      university: null,
      executive_list: [],
      executive: [],
      fe_ir_list: [],
      fe_ir: [],
      cost: 0,
      search: "",
      headers: [
        {
          sortable: false,
          text: "Service Name",
          align: "center",
          value: "service_name",
          class: ["table-header"],
          width: 120,
        },
        {
          sortable: false,
          text: "University Cost",
          align: "center",
          value: "university_cost",
          class: ["table-header"],
          width: 120,
        },
        {
          sortable: false,
          text: "University Cost/Document",
          align: "center",
          value: "university_cost_per_document",
          class: ["table-header"],
          width: 120,
        },
        {
          sortable: false,
          text: "Additional Cost(FE/IR Cost)",
          align: "center",
          value: "additional_cost",
          class: ["table-header"],
          width: 120,
        },
      ],
      tableItems: [],
      tableLoader: false,
    };
  },
  computed: {
    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },
    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    page_count() {
      this.getServiceCostList();
    },
    page_number() {
      this.getServiceCostList();
    },
    search() {
      this.debounceGetList();
    },
  },
  created() {
    this.debounceGetList = _.debounce(function () {
      this.getServiceCostList();
    }, 500);
  },
  mounted() {
    this.getUniversityDetail();
    this.getExecutiveList();
    this.getFeIrList();
    this.getServiceCostList();
  },
  methods: {
    ...mapActions({
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
      addUniversity: "admin/addUniversity",
      showSnackbar: "snackBar/showSnackbar",
    }),
    goBack() {
      this.$router.go(-1);
    },
    deleteUser() {
      const onSuccess = (res) => {
        this.toggleConfirmationDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.$router.go(-1);
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      let params = {};
      params["university_id"] = this.$route.params.id;

      return this.$request(this.$keys.DELETE, this.$urls.university.delete, {
        params,
        onSuccess,
        onFinally,
      });
    },
    getUniversityDetail() {
      this.basic_info_loading = true;
      const onSuccess = (res) => {
        this.university = res.data.data;
        this.executive = [];
        for (let id of res.data.data.executive) {
          this.executive.push(id.id);
        }
        this.fe_ir = [];
        for (let id of res.data.data.fe_ir) {
          this.fe_ir.push(id.id);
        }
      };

      const onFinally = () => {
        this.basic_info_loading = false;
      };

      let params = {};
      params["university_id"] = this.$route.params.id;

      return this.$request(this.$keys.GET, this.$urls.university.detail, {
        params,
        onSuccess,
        onFinally,
      });
    },
    getExecutiveList() {
      const onSuccess = (res) => {
        this.executive_list = res.data.data;
      };

      let params = {};
      params["role"] = "operations_executive";

      return this.$request(this.$keys.GET, apiUrl.team_lite_list, {
        params,
        onSuccess,
      });
    },
    getFeIrList() {
      const onSuccess = (res) => {
        this.fe_ir_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.fe_ir.list, {
        onSuccess,
      });
    },
    onFeIrSave() {
      const onSuccess = () => {
        this.getUniversityDetail();
      };

      let data = {};
      data["fe_ir"] = this.fe_ir;

      let params = {};
      params["university_id"] = this.$route.params.id;

      return this.$request(this.$keys.PUT, this.$urls.university.update, {
        params,
        data,
        onSuccess,
      });
    },
    onExecutiveSave() {
      const onSuccess = () => {
        this.getUniversityDetail();
      };

      let data = {};
      data["executive"] = this.executive;

      let params = {};
      params["university_id"] = this.$route.params.id;

      return this.$request(this.$keys.PUT, this.$urls.university.update, {
        params,
        data,
        onSuccess,
      });
    },
    getServiceCostList() {
      this.tableLoader = true;

      const onSuccess = (res) => {
        this.tableItems = res.data.data;
      };

      const onFinally = () => {
        this.tableLoader = false;
      };

      const params = {
        apply_pagination: false,
        university_id: this.$route.params.id,
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.university.serviceCost.list,
        { params, onSuccess, onFinally }
      );
    },
    onSave(id, key) {
      const onSuccess = () => {
        this.getServiceCostList();
      };

      let data = {};
      if (this.cost) data[key] = this.cost;
      else data[key] = 0;

      let params = {};
      params["university_service_cost_id"] = id;

      return this.$request(
        this.$keys.PUT,
        this.$urls.university.serviceCost.update,
        { params, data, onSuccess }
      );
    },
  },
};
</script>
