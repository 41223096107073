export default {
  //University
  university__list__lite: "/university/list-lite/",

  //Eca Body
  eca_body_lite_list: "/eca/list-lite/",

  //Team
  team_lite_list: "/team/list-lite/",

};
